import React from "react"
import {Helmet} from "react-helmet";
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Layout from "@utils/layout"
import SEO from "@utils/seo"
import Img from "gatsby-image"
import Button from "@atoms/button"
import ContentBlock from "@atoms/contentBlock"
import Container from "@atoms/container"
import List from "@atoms/list"
import SectionHeader from "@molecules/sectionHeader"
import SectionSetup from "@molecules/sectionSetup"
import { media } from "@utils/media"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query IndexImagesQuery {
      bob: file(relativePath: { eq: "bob-ross.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home" />
      <Helmet>
        <script async data-uid="ce0004af06" src="https://witty-writer-583.ck.page/ce0004af06/index.js"></script>
      </Helmet>
      <SectionHeader />
      <HeaderCurve fill="none" viewBox="0 0 1680 232">
        <path
          fill="#000"
          d="M0 0h1680v119.847S1428.43 231 1260 231C967.343 231 712.766 9.26 420 9.26 251.618 9.26 0 119.848 0 119.848V0z"
        ></path>
      </HeaderCurve>
      <SectionSetup />
      <ContentContainer contentMain grid gridSplit>
        <Image>
          <Img fluid={data.bob.childImageSharp.fluid} />
        </Image>
        <ContentBlock>
          <h2>
            You create the artwork. We do the rest. 
          </h2>
          <List>
            <li>Easily upload your artwork and variations</li> 
            <li>Automatically generate randomised unique avatars</li>
            <li>Create a web 3.0 landing page</li>
            <li>Explain your roadmap and vision for your characters</li>
            <li>Allow anyone with a wallet to mint</li>
          </List>
          <Button
            href="https://witty-writer-583.ck.page/ce0004af06"
            aria-label=""
            data-formkit-toggle="ce0004af06"
          >
            Join the waitlist
          </Button>
        </ContentBlock>
      </ContentContainer>
    </Layout>
  )
}

export default IndexPage

const Image = styled.div`
  width: 80%;
  margin: 0 auto;
  margin-bottom: 2rem;

  @media ${media.sm} {
    width: 100%;
  }
`

const ContentContainer = styled(Container)`
  flex-direction: column;
  display: flex;
  
  &:nth-of-type(odd) {
    direction: rtl;

    div {
      direction: ltr;
    }
  }

  h2 {
    font-size: 1.5rem;
  }

  @media ${media.sm} {
    h2 {
      font-size: 2rem;
    }
  }

  @media ${media.md} {
    flex-direction: row;
  }

`

const HeaderCurve = styled.svg`
  margin-top: -1px;
  height: auto;

  @media ${media.sm} {
    margin:0;
  }
`
