import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { media } from "@utils/media"
import Button from "@atoms/button"

const SectionHeader = () => {
  const data = useStaticQuery(graphql`
    query HeaderImageQuery {
      desktopImage: file(relativePath: { eq: "header-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      },

      mobileImage: file(relativePath: { eq: "mobile-header-image.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      },
    }
  `)

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: media.sm,
    }
  ]


  return (
    <HeaderContainer contentTop grid gridSplit backgroundSecondary>
      <Content>
        <h1>Launch the next big NFT avatars.</h1>
        <p>No code required.</p>
        <ButtonContainer>
          <Button            
            href="https://witty-writer-583.ck.page/ce0004af06"
            aria-label=""
            data-formkit-toggle="ce0004af06"
          >
            Join the waitlist
          </Button>
        </ButtonContainer>
      </Content>
      <Image>
        <Img fluid={sources} />
      </Image>
    </HeaderContainer>
  )
}

export default SectionHeader

const HeaderContainer = styled.header`
  align-items: center;
  background-color: ${props => props.theme.colors.secondary};
  box-sizing: border-box;
  color: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  padding: 0 var(--spacingContent);

  @media ${media.sm} {
    flex-direction: column;
  }

  @media ${media.md} {
    height: 100vh;
    flex-direction: row;
    min-height: 800px;
  }
`

const Image = styled.div`
  display: block;
  width: 100%;
  padding: 1rem 0 2rem 0;

  @media ${media.sm} {
    flex-basis: 100%;
    width: 70%;
    margin-left: 18rem;
    margin-top: -7rem;
  }

  @media ${media.md} {
    margin: 0;
    padding: 0;
    width: 40%;
    flex-basis: 40%;
  }

  @media ${media.lg} {
    width: 60%;
    flex-basis: 60%;
  }

  .gatsby-image-wrapper {
    width: 80%;
    margin: 0 auto;
  }
`

const Content = styled.div`
  display: block;
  flex-basis: 100%;
  width: 100%;

  h1 {
    margin-bottom: 0;
    font-size: 3rem;
  }

  @media ${media.sm} {
    padding-top: 5rem;

    h1 {
      font-size: 3.5rem;
    }
  }

  @media ${media.md} {
    padding: 0;
    width: 60%;
    flex-basis: 60%;
  }

  @media ${media.lg} {
    width: 40%;
    flex-basis: 40%;
  }
`

const ButtonContainer = styled.div`
  padding-top: 1rem;
  margin-bottom: 3rem;

  @media ${media.md} {
    margin: 0;
  }
`
